import React, { useEffect, useState } from "react";
import fileTypeDescriptions from './fileTypeDescriptions';
import LayoutDetail from "../../../components/layout/LayoutDetail";
import "../shipment.css";
import { basename } from "../../../Config";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Button from "@mui/material/Button";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import DocUploadShipmentSidebar from "../../../components/sidebar/DocUploadShipmentSidebar";
import { API_PATH } from "../../../constant/apiPath";
import instance from "../../../api/Interceptor";
import { useLocation, useNavigate } from "react-router-dom";
import { Skeleton, Tooltip } from "@mui/material";
import { toast } from "react-hot-toast";
import { genrateDownloadLink } from "../../../utils/utility";
import urlResolver from "../../../utils/urlResolver";
import { AuthContext } from "../../../store/context/AuthProvider";
import { useContext } from "react";
import { Routeconstant } from "../../../routing/utils/RouteConstant";

const Documentation = () => {
  const [docUpload, setDocUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [docList, setDocList] = useState({});
  const [docListOld, setDocListOld] = useState({});
  const [filter, setFilter] = useState("ALL");
  const location = useLocation();
  const context = useContext(AuthContext);

  // ----------------------------------------
  // const [checkALL, setCheckAll] = useState(false);
  // const [individualCheckArr, setIndividualCheckArr] = useState([]);

  const documentList = () => {
    setLoading(true);
    const body = {
      ShipmentID: location?.state?.ShipmentId ?? "",
    };
    instance
      .post(API_PATH.GET_SHIPMENT_DOCUMENT_LIST, body)
      .then((res) => {
        if (res.data) {
          setDocList(res?.data);
          setDocListOld(res?.data);
        } else {
          setDocList(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (context?.shipmentId?.length !== 0) documentList();
  }, [location?.state?.ShipmentId]);

  const downloadDocs = (item) => {
    const body = {
      ShipmentID: item?.SC_JobNum ?? "",
      DocumentPk: item?.SC_PK ?? "",
    };

    instance
      .post(API_PATH.GET_DOCUMENT_DATA, body)
      .then((i) => {
        const downloadLink = document.createElement("a");
        const prefix = genrateDownloadLink(i?.data[0]?.SC_FileName ?? "");
        downloadLink.href = `${prefix}${i?.data[0]?.ImageDataVC ?? ""}`;
        if (prefix && i?.data[0]?.ImageDataVC) {
          downloadLink.download = i?.data[0]?.SC_FileName ?? "";
          downloadLink.click();
        } else {
          toast.error("Invalid document found!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (context?.shipmentId?.length === 0) {
      navigate(Routeconstant.SHIPMENT_LISTING);
    }
  }, []);
  return (
    <LayoutDetail>
      {docUpload ? (
        <DocUploadShipmentSidebar
          setDocUpload={setDocUpload}
          docUpload={docUpload}
        />
      ) : null}
      <div className="p-2">
        <p className="basic-head font-shipment">Documentation</p>
      </div>
      {loading ? (
        <div className="skeleton-div">
          <Skeleton style={{ height: "3rem" }} />
          <Skeleton style={{ height: "30rem" }} />
        </div>
      ) : (
        <>
          {docList?.DocumentModelCollection?.length ? (
            <>
              <div className="d-flex justify-content-around align-items-center flex-class">
                <button
                  // key={item}
                  // value={item}
                  className={
                    filter === "ALL"
                      ? "btn-doc px-5 py-1 backgroundColor font-shipment"
                      : "btn-doc px-5 py-1 font-shipment"
                  }
                  onClick={(e) => {
                    setFilter("ALL");
                    const temp = { ...docListOld };
                    setDocList(temp);
                  }}
                >
                  ALL
                </button>
                {docList.DocumentTypes.map((item) => {
                  return (
                    <button
                      key={item}
                      value={item}
                      className={
                        filter === item
                          ? "btn-doc px-5 py-1 backgroundColor font-shipment"
                          : "btn-doc px-5 py-1 font-shipment"
                      }
                      onClick={(e) => {
                        setFilter(e.target.value);
                        const temp = { ...docListOld };
                        const temp2 = temp.DocumentModelCollection.filter(
                          (item) => {
                            return item.SC_DocType === e.target.value;
                          }
                        );
                        setDocList({
                          ...docList,
                          DocumentModelCollection: [...temp2],
                        });
                      }}
                    >
                      {item}
                    </button>
                  );
                })}
              </div>
              <table className="w-100 documentation-info mt-3 tableBody-div">
                <thead>
                  <tr className="documentation table">
                    {/* <th>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onClick={() => {
                            setCheckAll(!checkALL);
                            setIndividualCheckArr([]);
                          }}
                          value=""
                          id="defaultCheck1"
                        />
                        <span style={{ paddingLeft: "3rem" }}>Name</span>
                      </div>{" "}
                    </th> */}
                    <th className="font-shipment">Name</th>
                    <th className="font-shipment">Type </th>
                    <th className="font-shipment">File type</th>
                    <th className="d-flex justify-content-center shadow-none">
                      <img
                        src={urlResolver("assets/dotted.svg")}
                        style={{ visibility: "hidden" }}
                      />
                    </th>
                  </tr>
                </thead>
                <>
                  <tbody>
                    {docList.DocumentModelCollection.map((i, index) => {
                      return (
                        <tr className="documentation-row" key={index}>
                          {/* <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="defaultCheck1"
                                onClick={() => {
                                  if (individualCheckArr.includes(index)) {
                                    const updatedCheckArr =
                                      individualCheckArr.filter(
                                        (obj) => obj !== index
                                      );
                                    setIndividualCheckArr(updatedCheckArr);
                                  } else {
                                    const updatedCheckArr = [
                                      ...individualCheckArr,
                                      index,
                                    ];
                                    setIndividualCheckArr(updatedCheckArr);
                                  }
                                }}
                                checked={
                                  checkALL
                                    ? true
                                    : individualCheckArr.includes(index)
                                }
                              />
                              <span
                                className="invoice"
                                style={{ paddingLeft: "3rem" }}
                              >
                                {i?.SC_FileName ?? "N/A"}{" "}
                              </span>
                            </div>{" "}
                          </td> */}
                        
                          <td className="invoice font-shipment" title={i?.SC_Desc ?? 'N/A'}>
                            {i?.SC_FileName ?? "N/A"}{" "}
                          </td>
                          <td className="font-shipment">
                          <div className='col-1 wordWrap-doc font-shipment'>
                            <Tooltip title={fileTypeDescriptions[i?.SC_DocType] ?? 'N/A'}>
                              <span data-tooltip={i?.SC_DocType ?? 'N/A'}>
                                {i?.SC_DocType ?? 'N/A'}
                              </span>
                            </Tooltip>
                          </div></td>
                          <td className="font-shipment">
                            {i?.SC_FileName.split(".")[1] ?? "N/A"}

                            {/* <Button>
                              <PictureAsPdfOutlinedIcon className="pdf-icon" />
                            </Button> */}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <Button
                                variant="outlined"
                                className="mr-2 font-shipment"
                                onClick={() => {
                                  downloadDocs(i);
                                }}
                              >
                                <FileDownloadOutlinedIcon />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              </table>

              {/*--Mobile Respnsive */}
              <>
                {docList.DocumentModelCollection.map((i, index) => {
                  return (
                    <div
                      class="card sip-card mt-3"
                      style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                    >
                      <div className="d-flex align-items-center">
                        <div class="card-body">
                          <ul
                            className="list-inline"
                            style={{ display: "flex" }}
                          >
                            <li className="font-shipment">Name</li>
                            <li className="font-shipment ps-2 w-name" title={i?.SC_Desc ?? 'N/A'}>
                              {i?.SC_FileName ?? "N/A"}{" "}
                            </li>
                          </ul>
                          <ul
                            className="list-inline"
                            style={{ display: "flex" }}
                          >
                            <li className="font-shipment">Type</li>
                            <li className="ps-2 font-shipment" title={i?.SC_Desc ?? 'N/A'}>
                              {" "}
                              {i?.SC_DocType ?? "N/A"}
                            </li>
                          </ul>
                          <ul
                            className="list-inline"
                            style={{ display: "flex" }}
                          >
                            <li className="font-shipment">File Type</li>
                            <li className="ps-2 font-shipment">
                              {" "}
                              {i?.SC_FileName.split(".")[1] ?? "N/A"}
                            </li>
                          </ul>
                          
                        </div>

                        <Button
                          variant="outlined"
                          className="mr-5 font-shipment"
                          onClick={() => {
                            downloadDocs(i);
                          }}
                        >
                          <FileDownloadOutlinedIcon />
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </>
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center w-100 ">
              No document found!
            </div>
          )}
          <img
            onClick={() => {
              setDocUpload(true);
            }}
            src={urlResolver("assets/DocumentUploadIcon.svg")}
            className="doc-upload-icon"
          />
        </>
      )}
    </LayoutDetail>
  );
};

export default Documentation;
